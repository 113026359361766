<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="6" align="middle">
					<Images width="100%" image="theme-ed/tech5.png" />
				</GridContainer>
				<GridContainer size="6" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="4" color="primary" uppercase>About Us</Heading>
						<Heading size="2">An Award Winning Digital Marketing Agency</Heading>
						<Paragraph micro>Nulla facilisis sapien vel arcu imperdiet, id blandit turpis auctor. Integer auctor feugiat leo sed volutpat. Morbi vitae leo sit amet sapien ultrices convallis. Integer id vehicula lectus. Praesent non faucibus diam, ac imperdiet risus.</Paragraph>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" background="light" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Global Reach</Heading>
									<Paragraph micro>Up to 100%</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" background="light" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Big Experience</Heading>
									<Paragraph micro>Expert Worker</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" background="light" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Convenience</Heading>
									<Paragraph micro>To reach your target</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" background="light" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Team Strength</Heading>
									<Paragraph micro>Clients Satisfaction</Paragraph>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="search" background="light" color="primary" size="large" />
								<Heading size="4" align="center">Research</Heading>
								<Paragraph align="center" micro>Sed ligula turpis, ultricies sit amet ligula id, dictum pretium justo.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="timeline" background="light" color="primary" size="large" />
								<Heading size="4" align="center">Data Collection</Heading>
								<Paragraph align="center" micro>Sed ligula turpis, ultricies sit amet ligula id, dictum pretium justo.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="track_changes" background="light" color="primary" size="large" />
								<Heading size="4" align="center">Targeting</Heading>
								<Paragraph align="center" micro>Sed ligula turpis, ultricies sit amet ligula id, dictum pretium justo.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="work" background="light" color="primary" size="large" />
								<Heading size="4" align="center">Results</Heading>
								<Paragraph align="center" micro>Sed ligula turpis, ultricies sit amet ligula id, dictum pretium justo.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="primary">
			<Grid>
				<GridContainer size="6" align="middle">
					<Images width="100%" image="theme-ed/tech1.png" />
				</GridContainer>
				<GridContainer size="6" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">We Offer a Full Range of Digital Marketing Services!</Heading>
						<Paragraph micro>Praesent quis sapien eleifend sapien sodales eleifend. Proin ipsum lectus, semper eget tincidunt nec, sodales et justo. Nullam volutpat orci dictum tortor iaculis, eget bibendum ex convallis.</Paragraph>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Global Reach</Heading>
									<Paragraph micro>Up to 100%</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Big Experience</Heading>
									<Paragraph micro>Expert Worker</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Convenience</Heading>
									<Paragraph micro>To reach your target</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Team Strength</Heading>
									<Paragraph micro>Clients Satisfaction</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Button label="Start a Project" color="primary" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Stack direction="column" space="3">
				<Heading size="5" color="primary" uppercase>Our Team</Heading>
				<Heading size="2" align="center">Meet our Professional &amp; Expert<br/>Team Members</Heading>
					<Grid>
						<GridContainer size="3">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" align="top" image="theme-ed/staff1.png" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="2">
										<Heading size="6" color="primary">Senior Marketer</Heading>
										<Heading size="5">David Martin</Heading>
										<Icon icon="email" color="primary" />
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="3">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" image="theme-ed/staff2.png" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="2">
										<Heading size="6" color="primary">Data Analyst</Heading>
										<Heading size="5">Allison Bailey</Heading>
										<Icon icon="email" color="primary" />
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="3">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" image="theme-ed/staff4.png" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="2">
										<Heading size="6" color="primary">Sales Manager</Heading>
										<Heading size="5">Elizabeth Wright</Heading>
										<Icon icon="email" color="primary" />
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="3">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" align="top" image="theme-ed/staff3.png" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="2">
										<Heading size="6" color="primary">Developer</Heading>
										<Heading size="5">Victor Blake</Heading>
										<Icon icon="email" color="primary" />
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
					</Grid>
			</Stack>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/edison/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
