<template>
	<Page>
		<Header>
			<Stack justify="spaceBetween" width="auto">
				<Stack width="auto">
					<Icon icon="wb_twilight" color="primary" size="large" />
					<Heading size="2" margin="none" color="dark">Edison Digital</Heading>
				</Stack>
				<Stack space="3" justify="right" width="auto" visibility="hide show@md">
					<Link text="Home" display="inline" url="/edison" />
					<Link text="Company" display="inline" url="/edison/company" />
					<Link text="Services" display="inline" url="/edison/services" />
					<Link text="Blog" display="inline" url="/edison/blog" />
					<Link text="Contact" display="inline" url="/edison/contact" />
					<Icon icon="search" size="large" />
				</Stack>
				<Stack space="3" justify="center" width="auto" visibility="show hide@md" v-click-outside="setClose">
					<Link @click.native="setOpen()" display="inline" url="">
						<Icon icon="menu" color="dark" size="large" v-if="!isOpen" />
						<Icon icon="menu_open" color="dark" size="large" v-if="isOpen" />
					</Link>
					<Dropdown :open="isOpen">
						<Stack direction="column row@sm" space="3">
							<Link text="Home" display="inline" url="/edison" />
							<Link text="Company" display="inline" url="/edison/company" />
							<Link text="Services" display="inline" url="/edison/services" />
							<Link text="Blog" display="inline" url="/edison/blog" />
							<Link text="Contact" display="inline" url="/edison/contact" />
						</Stack>
					</Dropdown>
				</Stack>
			</Stack>
		</Header>
		<Body>
			<slot></slot>
		</Body>
		<Footer>
			<Section>
				<Grid>
					<GridContainer size="12 6@sm 4@md">
						<Stack direction="column" align="left">
							<Stack>
								<Icon icon="wb_twilight" color="tertiary" size="large" />
								<Heading size="2" margin="none" whiteout>Edison Digital</Heading>
							</Stack>
							<Stack>
								<Icon icon="email" color="light" size="large" />
								<Stack direction="column" align="left" space="1">
									<Heading size="5" whiteout>Email</Heading>
									<Link text="support@edison.com" color="light" url="/" />
								</Stack>
							</Stack>
							<Stack>
								<Icon icon="phone" color="light" size="large" />
								<Stack direction="column" align="left" space="1">
									<Heading size="5" whiteout>Phone:</Heading>
									<Link text="1 (234) 567 - 8901" color="light" url="/" />
								</Stack>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="6 3@sm 2@md">
						<Stack direction="column" align="left" space="3" visibility="hide show@sm">
							<Heading size="4" whiteout>Company</Heading>
							<Link text="Home" color="light" url="/edison" />
							<Link text="About Us" color="light" url="/edison/company" />
							<Link text="Meet Our Team" color="light" url="/edison/company" />
							<Link text="Support" color="light" url="/edison/contact" />
							<Link text="Contact" color="light" url="/edison/contact" />
						</Stack>
					</GridContainer>
					<GridContainer size="6 3@sm 2@md">
						<Stack direction="column" align="left" space="3" visibility="hide show@sm">
							<Heading size="4" whiteout>Useful Links</Heading>
							<Link text="FAQ" color="light" url="/edison/contact" />
							<Link text="Blog" color="light" url="/edison/blog" />
							<Link text="Services" color="light" url="/edison/services" />
							<Link text="Case Studies" color="light" url="/edison/services" />
							<Link text="Privacy Policy" color="light" url="/edison" />
						</Stack>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Stack direction="column" align="left" visibility="hide show@md">
							<Heading size="4" whiteout>Latest News</Heading>
							<Stack direction="column" align="left" space="3">
								<Heading size="5" whiteout>Consulted admitting is power accuteness.</Heading>
								<Stack space="1">
									<Icon icon="date_range" size="small" color="light" />
									<Heading size="6" whiteout uppercase>2 January 2022</Heading>
									<Heading size="6" whiteout uppercase>By Admin</Heading>
								</Stack>
								<Heading size="5" whiteout>Unsatiable entreaties may collecting Power.</Heading>
								<Stack space="1">
									<Icon icon="date_range" size="small" color="light" />
									<Heading size="6" whiteout uppercase>2 January 2022</Heading>
									<Heading size="6" whiteout uppercase>By Admin</Heading>
								</Stack>
							</Stack>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<Section size="small" color="dark">
				<Stack direction="column row@sm" justify="spaceBetween">
					<Heading size="6" whiteout>Copyright © 2022. Designed by Anna Robbins</Heading>
					<Stack justify="right" space="3" width="auto">
						<Link text="Facebook" color="light" url="/" display="inline" />
						<Link text="Twitter" color="light" url="/" display="inline" />
						<Link text="Instagram" color="light" url="/" display="inline" />
					</Stack>
				</Stack>
			</Section>
		</Footer>
	</Page>
</template>

<script>
export default {
  data() {
    return {
			isOpen: false,
		}
  },
	methods: {
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss" scoped>

</style>